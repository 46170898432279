import {PerfilModulo} from "app/shared/models/perfil-modulo";
import {SessaoService} from "../services/seguranca/sessao.service";

export class PerfilUtils {

    // VIVA VOZ
    public static isPerfilVivaAdmin(): boolean {
        return SessaoService.usuario.perfis.includes(PerfilModulo.VIVA_ADMIN.value);
    }

    public static isPerfilSisouVivaSoli(): boolean {
        return SessaoService.usuario.perfis.includes(PerfilModulo.VIVA_SOLI.value);
    }

    public static isPerfilSisouUnidadeCaixa(): boolean {
        return SessaoService.usuario.perfis.includes(PerfilModulo.SISOU_UNID_CAIXA.value);
    }

    // OUVIDORIA
    public static isPerfilOuvGestorSistema(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.OUV_GESTORSISTEMA.value);
    }

    public static isPerfilOuvCuradoria(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.OUV_CURADORIA.value);
    }

    public static isPerfilOuvSupervisor(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.OUV_SUPERVISOR.value);
    }

    public static isPerfilOuvOperador(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.OUV_OPERADOR.value);
    }

    public static isPerfilOuvSegundaIntancia(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.OUV_2INSTANCIA.value);
    }

    public static isPerfilOuvEstagMenor(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.OUV_ESTAGIARIOMENOR.value);
    }

    public static isPerfilOuvEstrategico(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.OUV_ESTRATEGIA.value);
    }

    public static isPerfilTerceirizadoGE(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SOU_TERCEIRIZADAGE.value);
    }

    public static isPerfilTerceirizadoOP(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SOU_TERCEIRIZADAOP.value);
    }

    //SAC

    public static isPerfilSacGestorAdmSistema(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_GEST_ADM_SISTEMA.value);
    }

    public static isPerfilSacGestorUnidadeAtendimento(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_GEST_UNID_ATEND.value);
    }

    public static isPerfilSacAuditoria(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_AUDITORIA.value);
    }

    public static isPerfilSacOperadorUnidadeAtendimento(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_OPER_UNID_ATEND.value);
    }

    public static isPerfilSacOperadorN2(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_OPER_N2.value);
    }

    public static isPerfilSacEstagiario(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_ESTAGIARIO.value);
    }

    public static isPerfilSacOperadorN1(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_OPER_N1.value);
    }

    public static isPerfilSacUnidCaixa(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SAC_UNID_CAIXA.value);
    }

    public static isPerfilHabilitadoUnidadeCaixa(){
        return SessaoService.usuario.perfis.includes(PerfilModulo.SOU_PERFIL_HABILITADO_UNID_CAIXA.value);
    }
}
