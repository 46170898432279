import {EnumTipoOcorrencia} from './../../../../shared/enums/EnumTipoOcorrencia';
import {MenuItem} from 'primeng/primeng';
import {PerfilModulo} from 'app/shared/models/perfil-modulo';
import {SessaoService} from '../../services/seguranca/sessao.service';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';
import {PerfilUtils} from '../../util/perfil-utils';

export class ItensMenuOuvidoria {

    constructor(
      private menuParent: MenuLateralComponent,
      private sessaoService: SessaoService
    ) {}

    private recuperaPerfis(): string[] {
        return this.sessaoService.getUsuario().perfisParaSelecao;
    }

    private possuiPermissaoOuvidoria(): boolean{
        const perfis = this.recuperaPerfis();
        return perfis.some( perfil => this.verificarPerfilOuvidoria(perfil));
    }

    //sisou unid caixa é temporário e não existirá
    private verificarPerfilOuvidoria(perfil: string): boolean{
        if(perfil.match(PerfilModulo.OUV_GESTORSISTEMA.value) ||
            perfil.match(PerfilModulo.OUV_CURADORIA.value) ||
            perfil.match(PerfilModulo.OUV_ESTAGIARIOMENOR.value) ||
            perfil.match(PerfilModulo.OUV_ESTRATEGIA.value) ||
            perfil.match(PerfilModulo.OUV_OPERADOR.value) ||
            perfil.match(PerfilModulo.OUV_SUPERVISOR.value) ||
            perfil.match(PerfilModulo.SISOU_UNID_CAIXA.value) ||
            perfil.match(PerfilModulo.SOU_TERCEIRIZADAGE.value) ||
            perfil.match(PerfilModulo.SOU_TERCEIRIZADAOP.value)){
            return true;
        }
        return false;
    }

    private verificarOuvGestor() : boolean {
        return this.recuperaPerfis().some( perfil => perfil === PerfilModulo.OUV_GESTORSISTEMA.value);
    }

    private verificarOuvOperador(): boolean {
        return this.recuperaPerfis().some( perfil => perfil === PerfilModulo.OUV_OPERADOR.value);
    }

    private verificarOuvSupervisor(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.OUV_SUPERVISOR.value);
    }

    private verificarOuvTerceirizadaGe(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.SOU_TERCEIRIZADAGE.value);
    }

    private verificarOuvTerceirizadaOp(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.SOU_TERCEIRIZADAOP.value);
    }

    private verificarOuvCuradoria(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.OUV_CURADORIA.value);
    }

    private verificarOuvEstagiarioMenor(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.OUV_ESTAGIARIOMENOR.value);
    }

    private verificarOuvEstrategia(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.OUV_ESTRATEGIA.value);
    }

    //temporário - não existirá sisou unid caixa mais
    private verificarOuvSisouUnidCaixa(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.SISOU_UNID_CAIXA.value);
    }

    private verificarOuv2Instancia(): boolean {
        return this.recuperaPerfis().some(perfil => perfil === PerfilModulo.OUV_2INSTANCIA.value);
    }

    public mostraRegistrar(){
        if(
            this.verificarOuvEstrategia() ||
            this.verificarOuvSisouUnidCaixa() ||
            this.verificarOuv2Instancia() ||
            this.verificarOuvSupervisor() ||
            this.verificarOuvCuradoria() ||
            this.verificarOuvGestor()
        ){
          return true;
        }else{
          return false;
        }
    }

    public mostraResponder(){
        if(
            this.verificarOuvEstrategia() ||
            this.verificarOuvOperador() ||
            this.verificarOuv2Instancia() ||
            this.verificarOuvSupervisor() ||
            this.verificarOuvCuradoria() ||
            this.verificarOuvGestor()
        ){
          return true;
        }else{
          return false;
        }
    }

    public get itens(): MenuItem[] {
        return [
            // Ouvidoria
            {
                label: "Ouvidoria",
                icon: "fa fa-cogs",
                visible: this.possuiPermissaoOuvidoria(),
                items: [
                    {
                        label: "Parametrização",
                        icon: "fa fa-angle-double-right",
                        visible: this.verificarOuvGestor() ||
                                 this.verificarOuvCuradoria() ||
                                 this.verificarOuvSupervisor(),
                        items: [
                            {
                                label: "Tabelas de Domínio",
                                icon: "fa fa-angle-double-right",
                                visible: this.verificarOuvGestor() ||
                                         this.verificarOuvCuradoria(),
                                items: [
                                    {
                                        label: "Origem",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/origem/",
                                        visible: this.verificarOuvGestor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Natureza",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/natureza/",
                                        visible: this.verificarOuvGestor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Produto",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/produto/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Problema",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/problema/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Canal",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/canal/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Pedido do Cliente",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/pedido-cliente/",
                                        visible: this.verificarOuvGestor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Problema de Comunicação",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/problema-comunicacao/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Tipo de Atendimento 0800",
                                        icon: "fa fa-angle-double-right",
                                        routerLink:
                                            "/administracao/parametrizacao/tipo-atendimento-zero-oitocentos/",
                                        visible: this.verificarOuvGestor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                      label: "Tipo de Atendimento Procon",
                                      icon: "fa fa-angle-double-right",
                                      routerLink: "/administracao/parametrizacao/tipo-atendimento-procon/",
                                      visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                      command: event => {
                                          this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                      }
                                    },
                                    {
                                        label: "Terceirizada",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/terceirizada/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                      label: "Habilitar Equipe Tratamento",
                                      icon: "fa fa-angle-double-right",
                                      routerLink: "/administracao/parametrizacao/habilitar-equipe-tratamento/",
                                      visible: this.verificarOuvGestor() ||
                                               this.verificarOuvSupervisor(),
                                      command: event => {
                                          this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                      }
                                  }
                                ]
                            },
                            {
                                label: "Tabelas de Vínculos",
                                icon: "fa fa-angle-double-right",
                                visible: this.verificarOuvGestor() ||
                                         this.verificarOuvCuradoria() ||
                                         this.verificarOuvSupervisor(),
                                items: [
                                    {
                                        label: "Categorização",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/categorizacao-ocorrencia-ouvidoria/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Equipe Tratamento",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/equipe-tratamento/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria() ||
                                                 this.verificarOuvSupervisor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Forma de Recebimento de Resposta",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/forma-recebimento-resposta-vinculo/",
                                        visible: this.verificarOuvGestor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Orientação Atendimento",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/orientacao-atendimento/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Correio Eletrônico",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/mensagem-email/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Template",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/template/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Dados de Procon",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/dados-procon/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Posto de Atendimento Procon",
                                        icon: "fa fa-angle-double-right",
                                        routerLink:
                                            "/administracao/parametrizacao/posto-atendimento-procon/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "SMS",
                                        icon: "fa fa-angle-double-right",
                                        routerLink:
                                            "/administracao/parametrizacao/sms/",
                                        visible: this.verificarOuvGestor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Unidade Bloqueada",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/unidade-bloqueada-so/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                ]
                            },
                            {
                                label: "Tabelas Acessórias",
                                icon: "fa fa-angle-double-right",
                                visible: this.verificarOuvGestor() ||
                                         this.verificarOuvCuradoria(),
                                items: [
                                    {
                                        label: "Penalidade",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/penalidade-ouv/",
                                        visible: this.verificarOuvGestor(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Causa Raiz",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/especificacoes/causa-raiz/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: 'Justificativa de Prorrogação',
                                        icon: 'fa fa-angle-double-right',
                                        routerLink: '/administracao/parametrizacao/justificativa-prorrogacao/',
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: (event) => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Palavra Proibida",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/palavra-proibida/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Motivo Retorno",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/motivo-retorno/",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Ajuda",
                                        icon: "fa  fa-question-circle",
                                        routerLink: "/subsidiaria/ajuda",
                                        visible: this.verificarOuvGestor() ||
                                                 this.verificarOuvCuradoria(),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: "Console Atendente",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/ocorrencia/ocorrencia-ouvidoria",
                        visible: this.verificarOuvOperador() ||
                                 this.verificarOuvGestor(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Console Supervisor",
                        icon: "fa fa-angle-double-right",
                        routerLink:
                            "/ocorrencia/ocorrencia-ouvidoria/supervisor",
                        visible: this.verificarOuvSupervisor() ||
                                 this.verificarOuvCuradoria(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Console Terceirizada",
                        icon: "fa fa-angle-double-right",
                        routerLink:
                            "/ocorrencia/ocorrencia-ouvidoria/terceirizado",
                        visible: this.verificarOuvGestor() ||
                                 this.verificarOuvTerceirizadaGe() ||
                                 this.verificarOuvTerceirizadaOp() ||
                                 this.verificarOuvSisouUnidCaixa(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Administrar Web Service",
                        icon: "fa fa-angle-double-right",
                        visible: this.verificarOuvGestor() ||
                                 this.verificarOuvSupervisor(),
                        items: [
                            {
                                label: "Console Rotina Correios",
                                icon: "fa fa-angle-double-right",
                                routerLink: "/ocorrencia/ocorrencia-ouvidoria/consulta-correio",
                                visible: this.verificarOuvGestor() ||
                                         this.verificarOuvSupervisor(),
                                command: event => {
                                    this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                }
                            },
                            {
                                label: "Console Rotina BACEN",
                                icon: "fa fa-angle-double-right",
                                routerLink: "/administracao/rdr-bacen",
                                visible: this.verificarOuvGestor(),
                                command: event => {
                                    this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                }
                            },
                            {
                                label: "Consultar Protocolo STA",
                                icon: "fa fa-angle-double-right",
                                routerLink: "/ocorrencia/satisfacao-sta",
                                visible: this.verificarOuvGestor(),
                                command: event => {
                                    this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                }
                            }]
                    },
                    {
                        label: "Console Gestor Sistema",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/ocorrencia/ocorrencia-ouvidoria/gestor",
                        visible: this.verificarOuvGestor(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Tratar Tarefa Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/tratar-ocorrencia/tratar-ocorrencia-ouv",
                        visible: this.verificarOuvSisouUnidCaixa(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Registrar Solicitação à Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/tratar-ocorrencia/solicitacao-ouv",
                        visible: true,
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Responder Solicitação à Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/tratar-ocorrencia/solicitacao-ouv",
                        visible: this.mostraResponder(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Consultar Ocorrência Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/ocorrencia/ocorrencia-ouvidoria/consultar-ocorrencia",
                        visible: this.verificarOuvCuradoria() ||
                                 this.verificarOuvEstrategia() ||
                                 this.verificarOuvGestor() ||
                                 this.verificarOuvOperador(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Construir Equipe Unidade Caixa",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/administracao/parametrizacao/equipe-unidade-caixa/",
                        visible: PerfilUtils.isPerfilHabilitadoUnidadeCaixa(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                ]
            },
        ];
    }

}
