import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {KeycloakService} from 'app/arquitetura/shared/services/seguranca/keycloak.service';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Usuario} from 'app/arquitetura/shared/models/cadastrobasico/usuario';

@Injectable()
export class UsuarioService extends CrudHttpClientService<Usuario> {
	static readonly USUARIO_SERVICE_URL: string = 'cadastrobasico/usuario';

	/**
	 * Recupera a url do endpoint correspondente
	 */
	static getEndpointUrl(): string {
		return UsuarioService.getBackendUrl() + UsuarioService.USUARIO_SERVICE_URL;
	}

	/**
	 * Recupera usuário pelo login de forma estática, para funcionar na
	 * inicialização do sistema
	 *
	 * @param id
	 */
	static consultarPorLogin(login: string): Promise<Usuario> {
		return new Promise((resolve, reject) => {
			let url: string = UsuarioService.getEndpointUrl().concat('/consultar-por-login?login=' + encodeURIComponent(login));

			let req: XMLHttpRequest = new XMLHttpRequest();
			req.open('GET', url, true);
			req.withCredentials = true;
			req.setRequestHeader('Authorization', 'bearer ' + KeycloakService.getLastRetrievedToken());
			req.setRequestHeader('Accept', 'application/json');

			req.onreadystatechange = function () {
				if (req.readyState == 4) {
					if (req.status == 200) {
						resolve(JSON.parse(req.responseText));
					} else {
						reject();
					}
				}
			}

			req.send();
		});
	}

	constructor(protected http: HttpClient) {
		super(UsuarioService.USUARIO_SERVICE_URL, http);
	}

	public alterarSenha() {
		return this.http.get(this.url + '/recuperar-senha', this.options());
	}

	public recuperarSenha(cpf) {
		return this.http.put(this.url + '/recuperar-senha/' + cpf, '', this.options());
	}

	/**
   * Utilizado no Form Atualizar Usuario para verificação da Flag ST_ATUALIZA_DADOS
   * @param entity
   */
	public atualizarDados(entity) {
		return this.http.put(this.url + '/atualiza-dados', entity, this.options());
	}
}
