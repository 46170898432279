import { PerfilModulo } from '../../../../shared/models/perfil-modulo';
import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';

@Injectable()
export class SacUnidCaixaGuard implements CanActivate {

    constructor(
        private sessaoService: SessaoService
    ) { }

    canActivate(): boolean {
        const perfisUsuario = this.sessaoService.getUsuario().perfisParaSelecao
        return perfisUsuario.some(perfil => this.possuiPerfilSac(perfil));
    }

    private possuiPerfilSac(perfil: string): boolean {
        if( perfil.match(PerfilModulo.SAC_UNID_CAIXA.value) ||
            perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value) ||
            perfil.match(PerfilModulo.SAC_AUDITORIA.value) ||
            perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value) ||
            perfil.match(PerfilModulo.SAC_OPER_UNID_ATEND.value) ||
            perfil.match(PerfilModulo.SAC_OPER_N2.value) ||
            perfil.match(PerfilModulo.SAC_OPER_N1.value) ||
            perfil.match(PerfilModulo.SAC_ESTAGIARIO.value)){
                return true;
            }
        return false;
    }
}
