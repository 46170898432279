import { PerfilModulo } from '../../../../shared/models/perfil-modulo';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';

import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';
import { Observable } from 'rxjs';

@Injectable()
export class ParametrizacaoNaturezaGuard implements CanActivate, CanActivateChild {

    constructor(
        private sessaoService: SessaoService
    ) { }


    canActivate(): boolean {
        const perfisUsuario = this.sessaoService.getUsuario().perfis
        return this.validarPermissao(perfisUsuario);
    }

    private validarPermissao(perfil: string[]): boolean {
        if( perfil.includes(PerfilModulo.VIVA_ADMIN.value) ||
            perfil.includes(PerfilModulo.SAC_GEST_ADM_SISTEMA.value) ||
            perfil.includes(PerfilModulo.SAC_AUDITORIA.value) ||
            perfil.includes(PerfilModulo.OUV_GESTORSISTEMA.value)){
                return true;
            }
        return false;
    }

    canActivateChild(): boolean{
        return this.canActivate();
    }


}
