export class Mensagem {

    MA004: string = 'Deseja realmente excluir?';
    MA005: string = 'A alteração deste item, irá impactar em todos os registros já cadastrados. Deseja realmente alterar?';
    MA017: string = 'Sem "Registrar e-mail - FILA ACOLHE", NÃO será possível registrar esta ocorrência!';
    MA020: string = ' Para efetivar a solução em linha em uma categorização, essa NÃO pode conter tarefas vinculadas! Para marcar essa categorização como "Solução em Linha", será necessário primeiramente cancelar as tarefas vinculadas a essa categorização!'

    MH054: string = 'Nome completo do canal de entrada da ocorrência.';
    MH062: string = 'Representa  a modalidade da Ocorrências que podem ser abertos pelos clientes.';
    MH063: string = 'Representa o portfólio comercializado pela CAIXA.';
    MH064: string = 'Representa o assunto que o cliente quer tratar.';
    MH065: string = 'Representa qual a tipo de ocorrência está sendo buscada se SAC ou Ouvidoria.';
    MH066: string = 'Data de Abertura: Data na qual a ocorrência foi registrada ou Data de Tratamento: Data na qual houve alguma movimentação na ocorrência.';
    MH067: string = 'Representa a opção para incluir as subordinadas da unidade no resultado da pesquisa.';
    MH068: string = 'Data de Abertura: Data na qual a ocorrência foi registrada ou Data de Resposta: Data na qual foi respondida a ocorrência.';
    MH069: string = 'Permite a seleção de uma unidade ou do Tipo de Unidade que ira buscar todas as unidades dentro do tipo selecionado.';
    MH070: string = 'Representa a etapa de tratamento da ocorrência.';
    MH071: string = 'Data de Abertura da ocorrência, Data de Abertura da(s) tarefa(s) e Data de resposta da(s) tarefa(s).';
    MH072: string = 'Evidencia o último status da ocorrência no seu processo de tratamento.';
    MH073: string = 'Evidencia a última tratativa da ocorrência no seu processo de tratamento.';
    MH074: string = 'Se refere ao tipo de natureza da ocorrência.';
    MH075: string = 'Se refere a um macroprocesso da CAIXA, podendo ser vários.';
    MH076: string = 'Está relacionado ao tipo de Assunto. Para cada Assunto poderá haver um ou mais itens.';
    MH077: string = 'É a matrícula do(a) empregado(a) que tratou a ocorrência.';
    MH078: string = 'Unidades vinculadas à unidade do usuário e que estão sob a gestão desta unidade.';
    MH071_VIVA: string = 'Data na qual a ocorrência foi registrada.';
    MH080: string = 'É a justificativa da inclusão da ocorrência.';
    MH081: string = 'Matrícula do empregado que registrou a ocorrência. Ao selecionar uma (1) ou mais matrículas solicitante, o usuário NÃO poderá selecionar a flag "Anônimo".';
    MH082: string = 'Registro da ocorrência sem identificação de matrícula, nome e CGC da unidade do empregado solicitante. Ao selecionar esta flag, o usuário NÃO poderá selecionar o filtro "Matrícula Solicitante".'

    MN001: string = 'Operação efetuada com sucesso.';
    MN002: string = 'Campo obrigatório.'
    MN006: string = 'Nenhum registro encontrado.';
    MN009: string = 'Informar ao menos Código ou Nome para consulta de unidades.';
    MN015: string = 'A unidade está bloqueada para o recebimento de ocorrência. Realize o desbloqueio para prosseguir.';
    MN040: string = 'Não é possível o registro da ocorrência e retorno para a lista, tendo em vista a ausência de informações acerca de: Manifesto e/ou Pedido do Cliente e/ou Dificuldade da Ocorrência e/ou Como o cliente quer ser respondido? e/ou Categorização e/ou Tarefa.';
    MN042: string = 'Não é possível o registro da ocorrência e resposta, tendo em vista a ausência de informações acerca de: Manifesto e/ou Pedido do Cliente e/ou Dificuldade da Ocorrência e/ou Como o cliente quer ser respondido? e/ou Categorização.';
    MN052: string = 'Não é possível acatar o prazo solicitado, pois o vencimento da tarefa após a prorrogação não pode ser maior que 2 (dois) dias úteis antes do vencimento final da ocorrência.';
    MN057: string = 'Não é possível o pré-registro da ocorrência, tendo em vista a ausência de informações acerca de: Manifesto e/ou Pedido do Cliente e/ou Dificuldade da Ocorrência e/ou Como o cliente quer ser respondido? e/ou Categorização.';
    MN060: string = 'Exclusão não permitida, a equipe deve ser composta de ao menos um membro!';
    MN061: string = 'Inclusão não permitida, o gestor responsável [nome do gestor] não possui função gerencial.';
    MN089: string = 'Existem definições de prioridades repetidas. Favor rever as opções de distribuição!';
    MN044: string = 'Não é possível o registro da tarefa, tendo em vista a ausência de informações acerca de: Unidade de Atendimento e/ou Prazo e/ou Template de resposta.';
    MN058: string = 'Não é possível a conclusão do tratamento e retorno para a lista, pois existe(m) tarefa(s) "Respondida(s)" sem avaliação!';
    MN059: string = 'Não é possível a conclusão do tratamento para a resposta, tendo em vista que há tarefa respondida, sem avaliação.';
    MN066: string = 'Conclusão não efetuada. existem categorizações não avaliadas!';
    MN027: string = 'Ao menos um arquivo deve ser selecionado.';
    MN110: string = 'Por favor, faça upload de pelo menos um arquivo.';
    MN144: string = 'Por favor, preencha a Data de Emissão.';
    MN021: string = 'Formato do arquivo inválido.';
    MN022: string = 'Arquivo ultrapassou o limite de 50 MB.';
    MN102: string = 'Empregado(s) informado(s) não são da mesma unidade.';
    MN119: string = 'Número de ocorrência inválido. Informe uma ocorrência que foi tratada pela unidade.';
    MN119_VIVA: string = 'Número de ocorrência inválido. Informe uma ocorrência que foi incluída por você. Verifique a relação de suas ocorrências em "Minhas Ocorrências".';
    MN120: string = 'Número de ocorrência não pertence a unidade. Informe uma ocorrência que foi tratada pela unidade.';
    MN122: string = 'Esse número de ocorrência não foi incluído por você. Verifique a relação de suas ocorrências em "Minhas Ocorrências".';
    MN127: string = 'O campo numérico deve ser preenchido com 4 números.';
    MN218: string = 'A matrícula informada é inválida!';
    MN219: string = 'Matrícula não encontrada.';
    MN220: string = 'A matrícula não pôde ser recuperada.';
    MN251: string = 'As ocorrências pendentes de tratamento foram redistribuídas aos demais membros da equipe.';
    MN260: string = 'O intervalo entre as datas selecionadas deve ser de no máximo seis meses. Por favor, ajuste as datas.';
    MN279: string = 'E-mail registrado com sucesso!';
    MN280: string = 'Essa ocorrência será registrada como "RESPONDIDA", pois todos os pedidos do cliente foram resolvidos em linha.';
    MN281: string = 'Obrigatório selecionar o formato de resposta';

    MENSAGEM_ACESSO_NEGADO: string = 'Acesso não permitido para este perfil.';
    MENSAGEM_PRORROGACAO_BACEN_MODELO_1: string = 'Em resposta à sua reclamação registrada no Banco Central, RDR Nº. {0}, a Ouvidoria da CAIXA esclarece que a sua demanda, cujo protocolo CAIXA é o número {1}, encontra-se ainda em tratamento. A área responsável permanece solucionando o quanto fora pleiteado; contudo, ainda há providências a serem tomadas e, destarte, o prazo cedido pelo Banco Central do Brasil para resposta necessitará ser prorrogado por mais 10 dias úteis. Concluindo, informamos que até o dia {2}, esta Centralizadora de Ouvidoria CAIXA efetuará contato conclusivo com a resposta final sobre seu relato.';
    MENSAGEM_PRORROGACAO_BACEN_MODELO_2: string = 'Solicitamos prorrogação, posto que devido a complexidade do caso e a exigência de demandar mais de uma área da CAIXA, será necessário um prazo maior. Demandante ficou ciente da prorrogação conforme mensagem em anexo.';
    MENSAGEM_PRORROGACAO_DEMAIS_ORIGENS: string = 'Em resposta à sua reclamação registrada na Ouvidoria da CAIXA, sob o protocolo Nº: {0}, informamos que ainda está em tratamento. A área responsável permanece na busca da solução do seu pedido. Precisaremos de mais alguns dias para concluir o tratamento. O pedido será prorrogado por mais {1} dias úteis. Informamos que até o dia {2} a Ouvidoria da CAIXA efetuará contato com a resposta sobre seu relato.';
    MENSAGEM_PRORROGACAO_BACEN_OCORRENCIA_REABERTA: string = 'Solicitamos prorrogação, posto que devido a complexidade do caso e a exigência de demandar mais de uma área da CAIXA, será necessário um prazo maior. Demandante ficou ciente da prorrogação conforme mensagem em anexo.';

}
