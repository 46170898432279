import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {OcorrenciaInterna} from 'app/shared/models/ocorrencia/ocorrencia-interna';
import {Pageable} from 'app/shared/models/pageable';
import {TratamentoOcorrenciaInterna} from 'app/shared/models/tratar-ocorrencia/tratamento-ocorrencia-interna';
import {TratamentoOcorrenciaInternaLote} from 'app/shared/models/tratar-ocorrencia/tratamento-ocorrencia-interna-lote';
import {Observable} from 'rxjs/Rx';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {RelatorioComFiltro} from 'app/shared/models/relatorio-com-filtro';
import {SessaoService} from "../../../arquitetura/shared/services/seguranca/sessao.service";


@Injectable()
export class TratarOcorrenciaInternaService extends CrudHttpClientService<OcorrenciaInterna> {
  constructor(
    protected http: HttpClient) {
    super('tratar-ocorrencia/tratar-ocorrencia-interna', http);
  }

  public consultarOcorrenciaPorId(idOcorrencia: number, idTipoMovimentacao: number): Observable<TratamentoOcorrenciaInterna> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('idOcorrencia', idOcorrencia.toString());
    httpParams = httpParams.set('idTipoMovimentacao', idTipoMovimentacao.toString());

    return this.http.get<TratamentoOcorrenciaInterna>(this.url + '/ocorrencia/', this.options({params: httpParams}));
  }

  public consultarTratamentoOcorrenciaPorMatricula(filtro: FiltroPaginado): Observable<Pageable> {
    return this.http.post<Pageable>(this.url + '/tratamento-ocorrencias', filtro, this.options());
  }

  public consultarTratamentoOcorrenciaPorMatriculaV2(filtro: FiltroPaginado): Observable<Pageable> {
    return this.http.post<Pageable>(this.url + '/tratamento-ocorrencias/v2', filtro, this.options());
  }

  public consultarCategorizacao(filtro: FiltroPaginado): Observable<Pageable> {
    return this.http.post<Pageable>(this.url + '/categorizacoes', filtro, this.options());
  }

  public recategorizar(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.put<void>(this.url + '/recategorizar', tratamento, this.options());
  }

  public incluirResposta(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-resposta', tratamento, this.options());
  }

  public incluirReabertura(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-reabertura', tratamento, this.options());
  }

  public alterar(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.post<void>(this.url + '/alterar', tratamento, this.options());
  }

  public incluirCancelamento(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-cancelamento', tratamento, this.options());
  }

  public incluirDesignacao(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-designacao', tratamento, this.options());
  }

  public incluirRespostaLote(lote: TratamentoOcorrenciaInternaLote): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-resposta-lote', lote, this.options());
  }

  public incluirDesignacaoLote(lote: TratamentoOcorrenciaInternaLote): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-designacao-lote', lote, this.options());
  }

  public incluirTransferencia(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-transferencia', tratamento, this.options());
  }

  public incluirTransferenciaLote(lote: TratamentoOcorrenciaInternaLote): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-transferencia-lote', lote, this.options());
  }

  public incluirProrrogacao(tratamento: TratamentoOcorrenciaInterna): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-prorrogacao', tratamento, this.options());
  }

  // public gerarRelatorioOcorrencia(id: number): Observable<any> {
  //   return this.http.post(this.url + '/gerar-relatorio-ocorrencia', id, this.options(
  //     { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  // }

  public gerarRelatorioDetalhar(id: number): Observable<any> {
    let matricula = SessaoService.usuario.matricula
    return this.http.post(this.url + '/gerar-relatorio-detalhar/'+matricula, id, this.options(
      { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  // public gerarRelatorioHistorico(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
	// 	let relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
	// 	// relatorioComFiltro.tipo = TipoRelatorio[tipo];
	// 	relatorioComFiltro.filtro = filtro;

	// 	return this.http.post(this.url + '/gerar-relatorio-historico', relatorioComFiltro, this.options(
	// 		{ observe: 'response' as 'body', responseType: 'arraybuffer' }));
  // }
  public gerarRelatorioHistorico(filtro: FiltroPaginado): Observable<any> {

		return this.http.post(this.url + '/gerar-relatorio-historico', filtro, this.options(
			{ observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  public gerarRelatorioLog(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
    const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
		relatorioComFiltro.tipo = TipoRelatorio[tipo];
    relatorioComFiltro.filtro = filtro;
    return this.http.post(this.url + '/gerar-relatorio-log', relatorioComFiltro, this.options(
      { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  public gerarRelatorioInclusao(id: number): Observable<any> {
    return this.http.post(this.url + '/gerar-relatorio-inclusao', id, this.options(
      { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  public consultarQuantidadeCategorizacao(filtro: FiltroPaginado): Observable<number> {
    return this.http.post<number>(this.url + '/quantidade-categorizacoes', filtro, this.options());
  }
}
