import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CrudHttpClientService } from "app/arquitetura/shared/services/crud-http-client.service";
import { MessageService } from "app/shared/components/messages/message.service";
import { Observable } from "rxjs/Rx";
import { FiltroPaginado } from "app/shared/models/filtroPaginado";
import { SelectItemModel } from "app/shared/models/select-item";
import { ClassificacaoOcorrenciaExterna } from "app/shared/models/administracao/parametrizacao/classificacao-ocorrencia-externa";
import { ClassificacaoPedidos } from "app/shared/models/administracao/parametrizacao/classificacao-pedidos";
import { OcorrenciaExternaClassificacaoAuditoria } from "app/shared/models/ocorrencia/ocorrencia-externa-classificacao-auditoria";
import { CategorizacaoOcorrencia } from "../../../models/administracao/parametrizacao/categorizacao-ocorrencia";
import { ProblemaReqModel } from "app/shared/models/administracao/parametrizacao/problema-req.model";

@Injectable()
export class ClassificacaoOcorrenciaExternaService extends CrudHttpClientService<ClassificacaoOcorrenciaExterna> {

  constructor(
    protected http: HttpClient,
    private messageService: MessageService
  ) {
    super("administracao/parametrizacao/classificacao-ocorrencia-externa", http);
  }

  public consultarTodos(): Observable<any> {
    return this.http.get<any>(this.url + '/todos', this.options());
  }

  public consultar(nome: string, situacao: string): any {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("noAssunto", nome);
    httpParams = httpParams.set("icSituacao", situacao ? situacao : "");

    return this.http.get<ClassificacaoOcorrenciaExterna[]>(
      this.url + "/consultar",
      this.options({ params: httpParams })
    );
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(
      this.url + "/consultar-paginado",
      filtro,
      this.options()
    );
  }

  public consultarPaginadoBancarioLoterico(
    filtro: FiltroPaginado
  ): Observable<any> {
    return this.http.post(
      this.url + "/consultar-paginado-bancario-loterico",
      filtro,
      this.options()
    );
  }

  public salvarListaCategorias(
    lista: ClassificacaoOcorrenciaExterna[]
  ): Observable<any> {
    return this.http.post(this.url + "/salvar-lista", lista, this.options());
  }

  public ativarInativar(
    categorizacaoOcorrencia: ClassificacaoOcorrenciaExterna
  ): Observable<any> {
    return this.http.put<ClassificacaoOcorrenciaExterna>(
      this.url + "/ativar-inativar",
      categorizacaoOcorrencia,
      this.options()
    );
  }

  public ativarInativarLote(
    categorizacoes: ClassificacaoOcorrenciaExterna[]
  ): Observable<any> {
    return this.http.put<CategorizacaoOcorrencia>(
      this.url + "/ativar-inativar-lote",
      categorizacoes,
      this.options()
    );
  }

  public searchAutocomplete(
    categorizacaoOcorrencia: ClassificacaoOcorrenciaExterna
  ): Observable<any> {
    return this.http.post(
      this.url + "/search-autocomplete/",
      categorizacaoOcorrencia,
      this.options()
    );
  }

  public consultarSelectsAssuntoCategorizacao(
    idTipoOcorrencia: number,
    idItem?: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );
    if (idItem) {
      httpParams = httpParams.set("idItem", idItem.toString());
    }

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-assunto-categorizacao",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsProdutoCategorizacao(
    idTipoOcorrencia: number,
    idProblema?: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );
    if (idProblema) {
      httpParams = httpParams.set("idProblema", idProblema.toString());
    }

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-produto-categorizacao",
      this.options({ params: httpParams })
    );
  }


  public consultarSelectsProblemasPorProdutosCategorizacaoPorModel(
    problemaReqModel: ProblemaReqModel
  ): Observable<SelectItemModel[]> {
    return this.http.post<SelectItemModel[]>(
      this.url + "/select-problema-by-produto-categorizacao",
      problemaReqModel,
      this.options()
    );
  }

  public consultarSelectsCategorizacaoPorProdutoProblemaModel(
    problemaReqModel: ProblemaReqModel
  ): Observable<SelectItemModel[]> {
    return this.http.post<SelectItemModel[]>(
      this.url + "/select-categorizacao-by-produto-problema-categorizacao",
      problemaReqModel,
      this.options()
    );
  }

  public consultarSelectsProdutoCategorizado(
    idOrigem: number,
    idTipoOcorrencia: number,
    idNatureza?: number,
    idProblema?: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("idOrigem", idOrigem.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );
    if (idNatureza) {
      httpParams = httpParams.set("idNatureza", idNatureza.toString());
    }
    if (idProblema) {
      httpParams = httpParams.set("idProblema", idProblema.toString());
    }

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-produto-categorizado",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsItemCategorizacao(
    idTipoOcorrencia: number,
    idAssunto?: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );
    if (idAssunto) {
      httpParams = httpParams.set("idAssunto", idAssunto.toString());
    }

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-item-categorizacao",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsProblemaCategorizacao(
    idTipoOcorrencia: number,
    idProduto?: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );
    if (idProduto) {
      httpParams = httpParams.set("idProduto", idProduto.toString());
    }

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-problema-categorizacao",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsProblemaCategorizado(
    idOrigem: number,
    idTipoOcorrencia: number,
    idNatureza?: number,
    idProduto?: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("idOrigem", idOrigem.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );
    if (idNatureza) {
      httpParams = httpParams.set("idNatureza", idNatureza.toString());
    }

    if (idProduto) {
      httpParams = httpParams.set("idProduto", idProduto.toString());
    }

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-problema-categorizado",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsNaturezasPorOrigem(
    origens: string,
    idTipoOcorrencia: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("origens", origens);
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-natureza-por-origem",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsProblemasPorNaturezaOrigem(
    idTipoOcorrencia: number,
    origens,
    naturezas
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("origens", origens.toString());
    httpParams = httpParams.set("naturezas", naturezas.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-produto-por-origem-natureza",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsProblemasPorNaturezaOrigemProduto(
    idTipoOcorrencia: number,
    origens,
    naturezas,
    produtos
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("origens", origens.toString());
    httpParams = httpParams.set("naturezas", naturezas.toString());
    httpParams = httpParams.set("produtos", produtos.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-problema-por-origem-natureza-produto",
      this.options({ params: httpParams })
    );
  }

  public consultarClassificacoes(
    idTipoOcorrencia: number,
    origens,
    naturezas,
    produtos,
    problema
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("origens", origens.toString());
    httpParams = httpParams.set("naturezas", naturezas.toString());
    httpParams = httpParams.set("produtos", produtos.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-problema-por-origem-natureza-produto",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsCategorizacao(
    idTipoOcorrencia: number,
    idProduto: number,
    idProblema: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("idProduto", idProduto.toString());
    httpParams = httpParams.set("idProblema", idProblema.toString());
    if (idTipoOcorrencia) {
      httpParams = httpParams.set(
        "idTipoOcorrencia",
        idTipoOcorrencia.toString()
      );
    }

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-categorizacao",
      this.options({ params: httpParams })
    );
  }

  public consultarClassificacoesOcorrenciaExterna(consultarClassificacoes: any): Observable<ClassificacaoPedidos[]> {
    return this.http.post<ClassificacaoPedidos[]>(
      this.url + "/buscar-classificacoes",
      consultarClassificacoes,
      this.options()
    );
  }

  public consultarTodasSelectsCategorizacao(
    idTipoOcorrencia: number
  ): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(
      this.url + "/select-todas-categorizacao/" + idTipoOcorrencia,
      this.options()
    );
  }

  public consultarClassificacao(
    idProduto: number,
    idProblema: number,
    idOrigem: number,
    idTipoOcorrencia: number,
    idNatureza?: number
  ): Observable<ClassificacaoPedidos> {
    let httpParams: HttpParams = new HttpParams();
    if (idProduto !== null) {
      httpParams = httpParams.set("idProduto", idProduto.toString());
    }
    if (idProblema !== null) {
      httpParams = httpParams.set("idProblema", idProblema.toString());
    }

    httpParams = httpParams.set("idOrigem", idOrigem.toString());
    httpParams = httpParams.set("idNatureza", idNatureza.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );

    return this.http.get<ClassificacaoPedidos>(
      this.url + "/buscar-classificacao",
      this.options({ params: httpParams })
    );
  }

  public consultarCategorizacoesPorOcorrencia(
    idOcorrencia: number
  ): Observable<ClassificacaoOcorrenciaExterna[]> {
    return this.http.get<ClassificacaoOcorrenciaExterna[]>(
      this.url + "/ocorrencia/" + idOcorrencia,
      this.options()
    );
  }

  public consultarCategorizacoesParaRegistroOcorrencia(
    idCategorizacao: number
  ): Observable<ClassificacaoOcorrenciaExterna> {
    return this.http.get<ClassificacaoOcorrenciaExterna>(
      this.url + "/" + idCategorizacao + "/registro-ocorrencia",
      this.options()
    );
  }

  public consultarSelectsCategorizacaoSac(
    idTipoOcorrencia: number,
    idOrigem: number,
    idNatureza: number
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("idOrigem", idOrigem.toString());
    httpParams = httpParams.set("idNatureza", idNatureza.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );
    return this.http.get<SelectItemModel[]>(
      this.url + "/select-categorizacao-origem-natureza-sac",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.get<SelectItemModel>(
      this.url + "/select-itens-perfis/" + idTipoOcorrencia,
      this.options()
    );
  }

  public consultarAditoriaPorOcorrencia(
    idOcorrencia: number
  ): Observable<OcorrenciaExternaClassificacaoAuditoria[]> {
    return this.http.get<OcorrenciaExternaClassificacaoAuditoria[]>(
      this.url + "/categorizacao-ocorrencia-externa-auditoria/" + idOcorrencia,
      this.options()
    );
  }

  public consultarTodasSelectsCategorizacaoPorTerceirizada(
    idTerceirizada: number
  ): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(
      this.url + "/select-todas-categorizacao-terceirizada/" + idTerceirizada,
      this.options()
    );
  }

  public consultarSelectItensPorEquipe(idEquipe: number): Observable<any> {
    return this.http.get<SelectItemModel>(
      this.url + "/select-itens-equipe/" + idEquipe,
      this.options()
    );
  }

  public consultarSelectItensPorUnidade(
    idUnidade: number,
    idUnidadeNatural: number
  ): Observable<any> {
    return this.http.get<SelectItemModel>(
      this.url + "/select-itens-unidade/" + idUnidade + "/" + idUnidadeNatural,
      this.options()
    );
  }

  consultarTodasSelectsCategorizacaoPorTerceirizadaUnidade(
    idUnidade: number,
    idUnidadeNatural: number
  ): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(
      this.url +
        "/select-todas-categorizacao-terceirizada-unidade/" +
        idUnidade +
        "/" +
        idUnidadeNatural,
      this.options()
    );
  }

  deleteLote(categorizacoesSelecionadas: ClassificacaoOcorrenciaExterna[]) {
    return this.http.post(
      this.url + "/excluir-lote",
      categorizacoesSelecionadas,
      this.options()
    );
  }

  public consultarSelectsProblemasPorNaturezaOrigemProdutoAntigo(
    idTipoOcorrencia: number,
    origens,
    naturezas,
    produtos
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("origens", origens.toString());
    httpParams = httpParams.set("naturezas", naturezas.toString());
    httpParams = httpParams.set("produtos", produtos.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-problema-por-origem-natureza-produto-antigo",
      this.options({ params: httpParams })
    );
  }

  public consultarSelectsProblemasPorNaturezaOrigemProdutoUnidade(
    idTipoOcorrencia: number,
    origens,
    naturezas,
    produtos
  ): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set("origens", origens.toString());
    httpParams = httpParams.set("naturezas", naturezas.toString());
    httpParams = httpParams.set("produtos", produtos.toString());
    httpParams = httpParams.set(
      "idTipoOcorrencia",
      idTipoOcorrencia.toString()
    );

    return this.http.get<SelectItemModel[]>(
      this.url + "/select-problema-por-origem-natureza-produto-unidade",
      this.options({ params: httpParams })
    );
  }
}
