import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Usuario} from 'app/arquitetura/shared/models/cadastrobasico/usuario';
import {ConfiguracoesSeguranca} from 'app/arquitetura/shared/models/seguranca/configuracoes-seguranca';
import {JwtToken} from 'app/arquitetura/shared/models/seguranca/jwt-token';
import {JwtTokenClaims} from 'app/arquitetura/shared/models/seguranca/jwt-token-claims';
import {UsuarioService} from 'app/arquitetura/shared/services/cadastrobasico/usuario.service';
import {ConfiguracaoSegurancaService} from 'app/arquitetura/shared/services/seguranca/configuracao-seguranca.service';
import {KeycloakService} from 'app/arquitetura/shared/services/seguranca/keycloak.service';
import {SistemaService} from 'app/arquitetura/shared/services/sobre/sistema.service';
import {UsuarioStorage} from 'app/arquitetura/shared/storage/usuario-storage';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Observable';
import {EquipeUsuarioStorage} from '../../storage/equipe-usuario-storage';
import {EquipeUsuario} from '../../models/cadastrobasico/equipe-usuario';
import {PerfilSelecionadoStorage} from '../../storage/perfil-selecionado-storage';
import {PerfilUsuarioSelecionado} from '../../models/cadastrobasico/perfil-usuario-selecionado';
import {PerfilModulo} from "../../../../shared/models/perfil-modulo";


@Injectable()
export class SessaoService {
    static usuario: Usuario = null;
    static configuracoesSeguranca: ConfiguracoesSeguranca = null;

    /**
     * Inicializa de forma estática toda a sessão do usuário, envolvendo:
     * - Integração com o Keycloak
     * - Recuperar o usuário logado
     * - Recuperar as configurações de segurança
     *
     * Essas chamadas tem que ser feitas todas de uma vez, antes de iniciar
     * a aplicação de fato, pois, envolvem chamadas assíncronas de HTTP que,
     * se não forem resolvidas de início, impactarão na ordem de execução do
     * sistema (um código que dependa da sessão estar carregada e o usuário
     * logado devidamente identificado pode não funcionar corretamente).
     */
    static init(): Promise<number> {
        return new Promise((resolve, reject) => {
            // Configurações de Segurança
            ConfiguracaoSegurancaService.get()
                .then((configuracoesSeguranca: ConfiguracoesSeguranca) => {
                    SessaoService.configuracoesSeguranca = configuracoesSeguranca;

                    if (configuracoesSeguranca.idCliente.toLowerCase().startsWith('cli-ser-')) {
                        configuracoesSeguranca.idCliente = 'cli-web-' +
                            configuracoesSeguranca.idCliente.substring('cli-ser-'.length);
                    }

                    // Keycloak
                    KeycloakService.init(configuracoesSeguranca.realm,
                        configuracoesSeguranca.idCliente, configuracoesSeguranca.urlServidorAutorizacao)
                        .then(() => {
                            UsuarioService.consultarPorLogin(KeycloakService.getUsername())
                                .then((usuario: Usuario) => {
                                    SessaoService.usuario = usuario;
                                    resolve(0);
                                })
                                .catch(() => resolve(3));
                        })
                        .catch(() => resolve(2));
                })
                .catch(() => resolve(1));
        });
    }

    public onIdleStart: EventEmitter<void>;
    public onIdleTimeLimit: EventEmitter<number>;
    public onIdleEnd: EventEmitter<void>;
    public onTimeout: EventEmitter<void>;

    private verificarRenovacaoToken: boolean;
    private jwtToken: JwtToken;
    private usuario: Usuario;
    private perfilUsuarioSelecionado: PerfilUsuarioSelecionado;
    private usuarioStorage: UsuarioStorage;
    private equipeUsuarioStorage: EquipeUsuarioStorage;
    private perfilSelecionadoStorage: PerfilSelecionadoStorage;

    constructor(
        private sistemaService: SistemaService,
        private idle: Idle,
        private router: Router,
        private jwtHelperService: JwtHelperService,
        private messageService: MessageService,
    ) {
        // Intercepta as interrupções padrões: clicks, rolagens, etc.
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.onIdleStart = new EventEmitter<void>();
        this.onIdleTimeLimit = new EventEmitter<number>();
        this.onIdleEnd = new EventEmitter<void>();
        this.onTimeout = new EventEmitter<void>();

        idle.onIdleStart.subscribe(() => {
            // Pergunta se quer continuar usando o sistema
            this.onIdleStart.emit();
        });
        idle.onTimeoutWarning.subscribe(
            (countdown) => {
                // Atualiza a pergunta com o tempo restante
                this.onIdleTimeLimit.emit(countdown);
            });
        idle.onIdleEnd.subscribe(() => {
            // Fecha a (janela da) pergunta anterior
            this.onIdleEnd.emit();
        });
        idle.onTimeout.subscribe(() => {
            // Fecha a (janela da) pergunta anterior e
            // redireciona para o login com uma mensagem de timeout
            this.onTimeout.emit();
        });

        this.usuarioStorage = new UsuarioStorage();
        this.equipeUsuarioStorage = new EquipeUsuarioStorage();
        this.perfilSelecionadoStorage = new PerfilSelecionadoStorage();
    }

    public inicializarSessao() {
        this.finalizarSessao();

        // Grava o token recebido para usar nas próximas requisições
        this.setToken(KeycloakService.getLastRetrievedToken(), true);

        // Define as configurações de segurança
        if (SessaoService.configuracoesSeguranca.tempoVidaToken <
            this.getTokenClaims().getExpirationTime()) {
            KeycloakService.setAccessTokenLifespan(
                SessaoService.configuracoesSeguranca.tempoVidaToken);
        } else {
            KeycloakService.setAccessTokenLifespan(
                this.getTokenClaims().getExpirationTime());
        }

        // Inicia o verificador de renovação de token
        //this.verificarRenovacaoToken = false;
        //this.iniciarControleRenovacaoToken();

        // Grava o usuário (e seus dados)
        this.setUsuario(SessaoService.usuario);
    }

    /**
     * Armazena o token de acesso e inicia o controle de idle
     * de sessão
     *
     * @param token
     */
    public setToken(token: string, inicioSessao: boolean = false) {
        this.jwtToken = new JwtToken();
        this.jwtToken.token = token;

        if (inicioSessao) {
            this.iniciarSessao();
        }
        this.inicializarControleTimeout(inicioSessao);
    }

    /**
     * Retorna o token de acesso
     */
    public getToken(): JwtToken {
        if (!this.jwtToken) {
            this.setToken(KeycloakService.getLastRetrievedToken(), true);
        }

        return this.jwtToken;
    }

    public atualizarToken(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                await KeycloakService.getToken();

                const token: string = KeycloakService.getLastRetrievedToken();
                this.setToken(token, false);

                resolve(token);
            } catch (error) {
                resolve(null);
            }
        });
    }

    /**
     * Retorna campos de informação do token
     */
    public getTokenClaims(): JwtTokenClaims {
        return this.getToken().getClaims(this.jwtHelperService);
    }

    /**
     * Armazena os dados do usuário logado
     *
     * @param usuario
     */
    private setUsuario(usuario: Usuario) {
        let perfisParaSelecao: string[] = [];
        perfisParaSelecao = this.getPerfisParaSelecao(usuario);
        usuario.perfisParaSelecao = perfisParaSelecao;

        this.usuario = new Usuario();
        this.usuario.idKeycloak = usuario.idKeycloak;
        this.usuario.login = usuario.login;
        this.usuario.matricula = usuario.matricula;
        this.usuario.nome = usuario.nome;
        this.usuario.cpf = usuario.cpf;
        this.usuario.email = usuario.email;
        this.usuario.unidade = usuario.unidade;
        this.usuario.numeroNaturalUnidade = usuario.numeroNaturalUnidade;
        this.usuario.ufUnidade = usuario.ufUnidade;
        this.usuario.perfis = usuario.perfis;
        this.usuario.recursos = usuario.recursos;
        this.usuario.modulo = usuario.modulo;
        this.usuario.frenteAtual = usuario.frenteAtual;
        this.usuario.idTipoOcorrencia = usuario.idTipoOcorrencia;
        this.usuario.idTipoOcorrenciaAtual = usuario.idTipoOcorrenciaAtual;
        this.usuario.perfisParaSelecao = usuario.perfisParaSelecao;
        this.usuario.cnpjUsuarioExterno = usuario.cnpjUsuarioExterno;
        this.usuario.usuarioExterno = usuario.usuarioExterno;
        this.usuario.perfisParaSelecao = this.removePerfilInexistente(this.usuario.perfisParaSelecao)

        this.usuarioStorage.gravar(this.usuario);
    }

    private getPerfisParaSelecao(usuario: Usuario): string[] {
      let perfisParaSelecao: string[] = [];
      usuario.perfis.forEach(perfil => {
          if (perfil.startsWith('OUV_') || perfil.startsWith('SAC_') || perfil.startsWith('VIVA_') || perfil.startsWith('SISOU_') || perfil.startsWith('SOU_AUD')) {
              perfisParaSelecao.push(perfil);
          }

          if (usuario.usuarioExterno && perfil.startsWith('CONV_SISOU_U_')) {
              perfisParaSelecao.push(perfil);
          }
      });

      return perfisParaSelecao;
    }

    /**
     * Gravar Usuário storage
     *
     * @param usuario
     */
    public gravarUsuarioStorage(usuario: Usuario) {

        this.usuarioStorage.gravar(this.usuario);
    }


    /**
     * Retorna o usuário logado
     */
    public getUsuario() {
        if (this.usuario) {
            this.usuario.matriculaNumerica = parseInt(this.usuario.matricula.replace('c', ''));
        }

        return this.usuario;
    }

    public atualizarPerfilSessao() {
        // Grava o usuário (e seus dados)
        this.setUsuario(SessaoService.usuario);
    }

    public isPerfilUsuarioSelecionado(): boolean {
        return this.getPerfilUsuarioSelecionado() &&
            this.getPerfilUsuarioSelecionado().perfil &&
            (this.getPerfilUsuarioSelecionado().perfil.length > 0);
    }

    public getPerfilUsuarioSelecionado(): PerfilUsuarioSelecionado {
        if (!this.perfilUsuarioSelecionado) {
            this.perfilUsuarioSelecionado = this.perfilSelecionadoStorage.ler();
        }

        return this.perfilUsuarioSelecionado;
    }

    public getPerfilUsuarioSelecionadoVivaSoli(): PerfilUsuarioSelecionado {
        if (!this.perfilUsuarioSelecionado) {
            this.perfilUsuarioSelecionado = this.perfilSelecionadoStorage.ler();
        }

        return this.perfilUsuarioSelecionado;
    }

    /**
     * Seta Equipe de Usuário na Sessão
     */
    public setEquipeUsuario(equipeUsuario: EquipeUsuario) {
        this.usuario.idEquipe = equipeUsuario.idEquipe;
        this.equipeUsuarioStorage.gravar(equipeUsuario);
    }

    /**
     * Recupera Equipe de usuário selecionado na sessão
     */
    public getEquipeUsuario() {
        return this.equipeUsuarioStorage.ler();
    }

    public getLogin() {
        return this.usuario.login;
    }

    /**
     * Verifica se tem um usuário logado
     */
    public isLogado() {
        return KeycloakService.isAuthenticated();
    }

    /**
     * Roteia para o home
     */
    public rotearParaHome() {
        this.router.navigate(['/home']);
    }

    /**
     * Roteia para o logout
     */
    public rotearParaLogout() {
        KeycloakService.logout();
    }

    public verificarAutenticacao(): boolean {
        if (!this.isLogado()) {
            this.rotearParaLogout();

            return false;
        }

        return true;
    }

    /**
     * Verifica se tem o perfil informado
     * @param perfil
     */
    public validarPermissao(perfil: string): boolean {
        if (!this.isLogado()) {
            return false;
        }

        return this.usuario.perfis.indexOf(perfil) !== -1;
    }

    /**
     * Apaga os dados da sessão e roteia para o login
     */
    public finalizarSessao(rotearParaLogin?: boolean, motivoSessaoExpirada?: boolean) {
        this.limparDadosSessao();
        this.idle.stop();
        if (rotearParaLogin) {
            this.limparPerfilSelecionado();
            this.limparEquipeUsuario();
            this.limparStatusTrabalho();
            KeycloakService.logout();
        }
        if (motivoSessaoExpirada) {
            this.limparPerfilSelecionado();
            this.limparEquipeUsuario();
            this.limparStatusTrabalho();
            this.messageService.addConfirmOk('Sua sessão expirou. Realize o login novamente.');
        }
    }

    /**
     * Apenas para sinalizar o início de uma sessão nova do AIM
     */
    private iniciarSessao() {
      return;
    }

    /**
     * Thread para renovação de token
     */
    private iniciarControleRenovacaoToken() {
        if (this.verificarRenovacaoToken) {
            return;
        }


        this.verificarRenovacaoToken = true;
        Observable
            // A cada minuto
            .interval(60000)
            // Controla quando deve parar a renovação do token
            .takeWhile(() => this.verificarRenovacaoToken)
            // Lógica de verificação e renovação do token
            .subscribe(i => {
                this.verificarERenovarToken();
            })
    }

    /**
     * Verifica se deve renovar o token e o renova
     */
    private verificarERenovarToken() {
        if (!this.isLogado()) {
            return;
        }

        if (this.getTokenClaims().getTimeSinceIssued() >=
            KeycloakService.getAccessTokenLifespan() - 1) {

            // fazer uma requisição neutra, de baixa performance, que renove
            // o token de forma automática (via o próprio processo do
            // http interceptor)
            this.sistemaService.info().subscribe();
        }
    }

    /**
     * Inicializa o controle de timeout (idle) da sessão
     *
     * @param inicioSessao define se a sessão está necessariamente sendo criada
     */
    private inicializarControleTimeout(inicioSessao: boolean = false) {
        let timeout: number = SessaoService.configuracoesSeguranca.tempoMaximoIdle;
        if (timeout <= 0) {
            timeout = KeycloakService.getAccessTokenLifespan();
        }

        // Só reinicia a checagem de idle caso esteja vindo do login
        if (inicioSessao ||
            // ou o serviço de checagem não tenha iniciado ainda
            (!this.idle.isRunning()) ||
            // ou o tempo de duração do token (e da sessão) tenha mudado
            (this.idle.getIdle() != this.getIdleTime(timeout))) {
            this.iniciarControleTimeout(timeout);
        }
    }

    /**
     * Inicia o controle de timeout da sessão
     *
     * @param timeout
     */
    private iniciarControleTimeout(timeout: number) {
        if (timeout <= 1) {
            return;
        }
        this.idle.setIdle(this.getIdleTime(timeout));		// Tempo para considerar Idle
        this.idle.setTimeout(120);	// Tempo após entrar em Idle para atingir o Timeout
        this.idle.watch();
    }

    private getIdleTime(timeout: number): number {
        return (timeout - 1) * 120;
    }

    /**
     * Apaga os dados da sessão
     */
    private limparDadosSessao() {
        this.jwtToken = null;
        this.usuario = null;
        this.limparUsuario();
        //this.limparPerfilSelecionado();
        //this.limparEquipeUsuario();
    }

    /**
     * Apagar dados usuário
     */
    public limparUsuario(): void {
        this.usuarioStorage.limpar();
    }

    /**
     * Apagar dados perfilSelecionado
     */
    public limparPerfilSelecionado(): void {
        this.perfilUsuarioSelecionado = null;
        this.perfilSelecionadoStorage.limpar();
    }

    /**
     * Apagar dados equipeUsuário
     */
    public limparEquipeUsuario(): void {
        this.equipeUsuarioStorage.limpar();
    }

    /**
     * Apagar dados status trabalho
     */
    public limparStatusTrabalho(): void {
        localStorage.removeItem('SISOU_INICIO_TRABALHO');
    }

    private removePerfilInexistente(perfisParaSelecao: string[]) {

        let seTemOuvSubsidiariaAntigoLocal: boolean = perfisParaSelecao.includes('OUV_SUBSIDIARIA');
        let seTemSacOperCaixaLocal: boolean = perfisParaSelecao.includes('SAC_OPER_CAIXA');

        if (seTemOuvSubsidiariaAntigoLocal) {
            perfisParaSelecao.splice(
                perfisParaSelecao.indexOf('OUV_SUBSIDIARIA'), 1);
        }

        if (seTemSacOperCaixaLocal) {
            perfisParaSelecao.splice(
                perfisParaSelecao.indexOf('SAC_OPER_CAIXA'), 1);
        }

        return perfisParaSelecao;

    }

    private verificarPerfilExterno(perfilSelecionado: string) {
        if (perfilSelecionado != null) {
            if (perfilSelecionado.includes(PerfilModulo.SOU_TERCEIRIZADAOP.value)) {
                return PerfilModulo.SOU_TERCEIRIZADAOP.value;
            }
            if (perfilSelecionado.includes(PerfilModulo.SOU_TERCEIRIZADAGE.value)) {
                return PerfilModulo.SOU_TERCEIRIZADAGE.value;
            }
        }
        return perfilSelecionado;
    }
}
