import { PerfilModulo } from '../../../../shared/models/perfil-modulo';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, RouterStateSnapshot, Route } from '@angular/router';

import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';

@Injectable()
export class VivaVozGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private sessaoService: SessaoService
    ) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    return this.canActivate()
  }
  canLoad(route: Route): boolean {
    return this.canActivate()
  }

    canActivate(): boolean {
        return this.sessaoService.getUsuario().perfis.includes(PerfilModulo.VIVA_ADMIN.value) ||
            this.sessaoService.getUsuario().perfis.includes(PerfilModulo.VIVA_SOLI.value)
    }
}
