// /-------------------------------------------------\
// | artefatos da biblioteca angular                 |
// \-------------------------------------------------/
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


// /-------------------------------------------------\
// | de terceiros (Bootstrap, etc.)        |
// \-------------------------------------------------/
import {ModalModule} from 'ngx-bootstrap/modal';

// /-------------------------------------------------\
// | artefatos compartilhados do projeto             |
// \-------------------------------------------------/
import {MessageResourceImpl} from 'app/app.message';
import {RequestInterceptor} from 'app/arquitetura/shared/interceptors/request.interceptor';
import {KeycloakService} from 'app/arquitetura/shared/services/seguranca/keycloak.service';
import {ServiceModule} from 'app/arquitetura/shared/services/service.module';
import {LoadingModule} from 'app/shared/components/loading/loading.module';
import {MessageResourceProvider} from 'app/shared/components/messages/message-resource-provider';
import {MessageModule} from 'app/shared/components/messages/message.module';
import {PaginationModule} from 'app/shared/components/pagination/pagination.module';
import {ValidationResourceProvider} from 'app/shared/components/validation/validation-resource-provider';
import {ValidationModule} from 'app/shared/components/validation/validation.module';
import {PipeModule} from 'app/shared/pipes/pipe.module';
import {AppServiceModule} from 'app/shared/services/app-service.module';

// /-------------------------------------------------\
// | artefatos/componentes da aplicação              |
// \-------------------------------------------------/
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {TextMaskModule} from 'angular2-text-mask';
import {AppRoutingModule} from 'app/app.routing.module';
import {AuthGuard} from 'app/arquitetura/shared/guards/security/auth.guard';
import {DadosUsuarioGuard} from 'app/arquitetura/shared/guards/security/dados-usuario.guard';
import { VivaVozGuard } from 'app/arquitetura/shared/guards/security/viva-voz.guard';
import { VivaAdminGuard } from './arquitetura/shared/guards/security/viva-admin.guard';
import { VivaSoliGuard } from './arquitetura/shared/guards/security/viva-soli.guard';
import { ParametrizacaoCategorizacaoOcorrenciaGuard } from './arquitetura/shared/guards/security/parametrizacao-categorizacao-ocorrencia.guard';
import { ParametrizacaoOrientacaoAtendimentoGuard } from './arquitetura/shared/guards/security/parametrizacao-orientacao-atendimento.guard';
import { ParametrizacaoOrigemGuard } from './arquitetura/shared/guards/security/parametrizacao-origem.guard';
import { ParametrizacaoNaturezaGuard } from './arquitetura/shared/guards/security/parametrizacao-natureza.guard';
import { ParametrizacaoMensagemEmailGuard } from './arquitetura/shared/guards/security/parametrizacao-mensagem-email.guard';
import {TemplatesModule} from 'app/arquitetura/shared/templates/templates.module';
import {NgxLoadingModule} from 'ngx-loading';
import {NgxMaskModule} from 'ngx-mask';
import {BreadcrumbModule, PanelMenuModule, SidebarModule} from 'primeng/primeng';
import {AppComponent} from './app.component';
import {DirectivesModule} from './arquitetura/shared/directives/directives.module';
import {LoaderInterceptor} from './arquitetura/shared/interceptors/loader.interceptor';
import {ComponenteModule} from './shared/components/componente.module';
import {TransferObject} from './shared/util/trasfer-object';
import {SacUnidCaixaGuard} from "./arquitetura/shared/guards/security/sac-unid-caixa.guard";



registerLocaleData(localePt);

// /--------------------------------------------------\
// | app.module.ts                                    |
// |--------------------------------------------------|
// | Módulo principal da aplicação (root module)      |
// \--------------------------------------------------/
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ServiceModule.forRoot(),
    AppServiceModule.forRoot(),
    MessageModule.forRoot(),
    DirectivesModule.forRoot(),
    ValidationModule,
    LoadingModule.forRoot(),
    PaginationModule.forRoot(),
    PipeModule.forRoot(),
    TemplatesModule,
    AppRoutingModule,
    PanelMenuModule,
    SidebarModule,
    TextMaskModule,
    BreadcrumbModule,
    ComponenteModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxLoadingModule.forRoot({})
  ],
  exports: [],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MessageResourceProvider,
      useValue: MessageResourceImpl
    },
    {
      provide: ValidationResourceProvider,
      useValue: MessageResourceImpl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    KeycloakService,
    AuthGuard,
    DadosUsuarioGuard,
    VivaAdminGuard,
    VivaSoliGuard,
    VivaVozGuard,
    SacUnidCaixaGuard,
    ParametrizacaoMensagemEmailGuard,
    ParametrizacaoNaturezaGuard,
    ParametrizacaoOrigemGuard,
    ParametrizacaoOrientacaoAtendimentoGuard,
    ParametrizacaoCategorizacaoOcorrenciaGuard,
    TransferObject
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,  NO_ERRORS_SCHEMA]
})
export class AppModule {}
