import {MenuItem} from 'primeng/primeng';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';
import {PerfilModulo} from 'app/shared/models/perfil-modulo';
import {SessaoService} from '../../services/seguranca/sessao.service';

export class ItensMenuPadrao {

  constructor(
    private menuParent: MenuLateralComponent,
    private sessaoService: SessaoService) {
  }

  private possuiPerfilAutorizado(): boolean {
    const perfisUsuario = this.sessaoService.getUsuario().perfis
    return perfisUsuario.some(perfil => this.verificaPerfilAutorizado(perfil))
  }

  private verificaPerfilAutorizado(perfil: string): boolean {
    if(
      perfil.match(PerfilModulo.OUV_GESTORSISTEMA.value) ||
      perfil.match(PerfilModulo.SAC_AUDITORIA.value) ||
      perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value) ||
      perfil.match(PerfilModulo.SAC_OPER_N2.value) ||
      perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value) ||
      perfil.match(PerfilModulo.SAC_UNID_CAIXA.value) ||
      perfil.match(PerfilModulo.SAC_OPER_UNID_ATEND.value) ||
      perfil.match(PerfilModulo.VIVA_ADMIN.value) ||
      perfil.match(PerfilModulo.VIVA_SOLI.value) ||
      perfil.match(PerfilModulo.SISOU_UNID_CAIXA.value) ||
      perfil.match(PerfilModulo.SOU_TERCEIRIZADAGE.value) ||
      perfil.match(PerfilModulo.SOU_TERCEIRIZADAOP.value)
    ) {
      return true;
    }
    return false;
  }

  public get itens(): MenuItem[] {
    return [
      {
        label: "Página Inicial",
        icon: "fa fa-cogs",
        routerLink: "/subsidiaria",
        visible: this.possuiPerfilAutorizado(),
        command: event => {
          this.menuParent.itemMenuClick(null);
        }
      }
    ];
  }

}

